@import url('https://fonts.googleapis.com/css2?family=League+Spartan&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,900;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Genos:wght@100;400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');

*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
    font-family: 'League Spartan', sans-serif;
}
body{
    background-color: black;
    width: 100%;
    overflow-x: hidden;
    /* -webkit-overflow-scrolling: touch;
    overflow-scrolling: touch; */
    /* overflow-x: hidden;
    overflow-y: scroll; */
    
}
::selection{
    color: #eb0e2f;
    /* background-color: #b5042e; */
}
h1{
    font-size: 50px;
    line-height: 64px;
    color: #222;
}
h2{
    font-size: 46px;
    line-height: 54px;
    color:#b3a99c;
}
h4{
    font-size: 20px;
    color:#b3a99c;
}
h6{
    font-weight: 700;
    font-size: 12px;
}
p{
    font-size: 18px;
    color: #bfb4b3;
    margin: 15px 0 20px 0;
}
.section-p1{
    padding:60px 80px;
}
.section-m1{
    margin:40px 0;
}
button.normal{
    display: flex;
    /* justify-content: center; */
    align-self: flex-end;
    width: fit-content;
    font-size: 18px;
    font-weight: 600;
    padding:13px 30px;
    margin-right: 100px;
    margin-bottom: 20px;
    color: #000;
    background-color: #dfe0fe;
    border-radius: 4px;
    cursor: pointer;
    border: none;
    outline: none;
    transition: 0.2s;
}
#header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 20px;
    background-color: black;
    box-shadow: 0 5px 15px rgba(224,214, 211, 0.3) ;
    z-index: 999;
    height: 100px;
}

#navbar{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}
#navbar li{
    list-style:none;
    padding: 0px 20px;
    position: relative;
}
#navbar li a{
    text-decoration: none;
    font-size: 16px;
    font-weight: 500;
    color: #b3a99c;
    transition: 0.3s ease;
}
#navbar li a:hover,
#navbar li a.active{
    color: #a92322 ;
    font-size: 110%; 
}
                                   /* everytime we use "::after" we have to put the content also its mandatory */
#navbar li a.active::after,
#navbar li a:hover::after{
    content: "";
    width: 40%;
    height: 2px;
    background: #a92322;
    position: absolute;
    bottom: -4px;
    left: 20px;
}
.cart-counter{
    min-width: 20px;
    text-align: center;
    background-color: #b5042e;
    position: absolute;
    padding: 2.5px;
    top: -5px;
    right: 5px;
    color: #b3a99c;
    font-size: 12px;
    line-height: 1;
    border-radius: 10px;
    font-weight: 500;
}
.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    animation: appear 0.4s ease forwards; 
    transform: translateY(-100px);
}
@keyframes appear {
    0% {
        transform: translateY(-100px);
    }
    100%{
        transform: translateY(0px);
    }
}

@media  (max-width: 768px) {
    #header {
        max-width: 1200px;
        margin: 0 auto;
    }
}
.logout {
    margin-top: 23px;
    cursor: pointer;
    font-size: 17px;
  }
  
  .logout:hover {
    border-bottom: 1px solid #b5042e;
  }

#hero{
    background-image: url("../images/wp9688170-jordan-shoe-desktop-wallpapers.jpg");
    height: 90vh;
    width: 100%;
    background-size: cover;
    background-position: top 10% right 0%;
    padding:0px 80px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
#hero h4{
    padding-bottom: 15px;

}
#hero h1{
    color: #a92322;
}
#hero button{
    font-size: 16px;
    background-color: #a92322;
    color: #bfb4b3;
    border:1px solid;
    overflow: hidden;
    padding:14px 60px 14px 60px ;
    cursor: pointer;
    font-weight: 700;
    /* border-radius: 100px 200% 200% 100px; */
}
#hero button:hover{
    font-weight: 700;
    transition: .3s ease;
    color: black;
    background-color: #b3a99c;
    border: 1px solid #b3a99c;
    
}

#product1{
    text-align: center;
}
#product1 > h1 {
    color: #b3a99c;
}
#product1 > p{
    color: #726a69;
}
#product1 .pro-container{
    display: flex;
    justify-content: space-between;
    padding-top: 20px;
}

#product1 .pro{
    width: 100%;
    min-width: 250px;
    padding: 10px 20px;
    cursor: pointer;
    margin: 15px 0;
    transition: 0.2s ease;
    position: relative;
}
#product1 .pro:hover{
    transform: scale(1.1);
    
}
#product1 .pro img{
    width: 100%;
}
#product1 .pro .des{
    text-align: center;
    padding: 10px 0;
}
#product1 .pro .des span{
    color: #b3a99c;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    font-size: 20px;
    font-weight: 500;
}
#product1 .pro .des h5{
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 25px;
    color: #b3a99c;
}
#product1 .pro .des i{
    font-size: 12px;
    color:rgb(243, 181, 25);
    padding-bottom: 10px;
}
#product1 .pro .des h4{
    padding-top: 7px;
    font-size: 50px;
    font-weight: 800;
    color: #a92322;
}
#product1 .pro .cart{
    width: 40px;
    height: 40px;
    line-height: 40px;
    border-radius: 50px;
    background-color:#e8f6ea;
    font-weight: 500;
    color: #a92322;
    position: absolute;
    bottom: 20px;
    right: 10px;
}
.logo{
    filter: invert(100%);
}
/* ======================================================= --> 3d tilt shoes */
.centered{
    display: flex;
    justify-content: center;
    align-items: center;
}
.bottle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    width: 1200px;
    transform-style: preserve-3d;
}

.bottle .socks {
    position: relative;
    width: 300px;
    height: 400px;
    margin: 40px;
    background: var(--socks-bg);
    border-radius: 20px;
    transform-style: preserve-3d;
    /* animation: bounce; */
}
.bounce {
	animation-name: bounce;
	animation-timing-function: ease;
	animation-iteration-count: infinite;
	animation-duration: 3s;
}
@keyframes bounce {
	0% {
		transform: translateY(0);
	} 50% {
		transform: translateY(-50px);
	} 100% {
		transform: translateY(0);
	}
}
.bottle .socks::before {
    content: 'NIKE';
    position: absolute;
    top: 20px;
    left: 20px;
    font-size: 6em;
    font-weight: 900;
    color: var(--card-bg-text);
    font-style: italic;
    opacity: 0;
    transition: 0.5s;
}

.bottle .socks::after {
    content: 'SHOES';
    position: absolute;
    bottom: 20px;
    right: 20px;
    font-size: 4.5em;
    font-weight: 900;
    color: var(--card-bg-text);
    font-style: italic;
    opacity: 0;
    transition: 0.5s;
}

.bottle .socks:hover::after,
.bottle .socks:hover::before {
    opacity: 0.04;
}

.bottle .socks .name {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    color: var(--name-color);
    width: 100%;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 75px);
    transition: 0.5s;
    opacity: 0;
    z-index: 10;
}

.bottle .socks:hover .name {
    top: 40px;
    opacity: 1;
}

.bottle .socks .buy {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform-style: preserve-3d;
    transform: translate3d(0, 0, 75px);
    color: #fff;
    background: #333;
    padding: 10px 25px;
    border-radius: 30px;
    text-decoration: none;
    transition: 0.5s;
    opacity: 0;
    z-index: 10;
}

.bottle .socks:hover .buy {
    bottom: 30px;
    opacity: 1;
}

.bottle .socks .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transition: 0.5s;
    background: #ffffff;
    transform-style: preserve-3d;
    z-index: 10;
    opacity: 1;
    transform: translate3d(-50%, -50%, 0px);
}

.bottle .socks:hover .circle {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    transition: 0.5s;
    background: #ffffff;
    transform-style: preserve-3d;
    z-index: 10;
    opacity: 1;
    transform: translate3d(-50%, -50%, 35px);
}

.bottle .socks .product {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 300px;
    transition: 0.5s;
    z-index: 11;
    transition: 0.5s;
    transform-style: preserve-3d;
    transform: translate3d(-50%, -50%, 0px) rotate(-15deg);
}

.bottle .socks:hover .product {
    transform: translate3d(-50%, -50%, 100px) rotate(-15deg);
}

.bottle .socks:nth-child(1) .circle,
.bottle .socks:nth-child(1) .buy {
    background: #9bdc28;
}

.bottle .socks:nth-child(2) .circle,
.bottle .socks:nth-child(2) .buy {
    background: #1DA3C3;
}

.bottle .socks:nth-child(3) .circle,
.bottle .socks:nth-child(3) .buy {
    background: #eb0e2f;
}

.product-month{
    text-align: center;
}
.product-month h2{
    color: #dfe0fe;
}

#month{
    text-align: center;
    padding-bottom: 40px;
}
#banner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
    background-image: url("../images/banner.jpeg");
    width: 100%;
    height: 60vh;
    background-size: cover;
    background-position: center;
}

#banner h2{
    color:#dfe0fe ;
    font-size: 40px;
    padding-top: 350px;
}
#banner h2 span{
    color: #b5042e;
}
#banner button:hover{
    background: #b5042e;
    color: #dfe0fe;
}
#newsletter{
    /* margin-top: 250px; */
    display: flex;
    justify-content: space-around;
    flex-wrap:wrap;
    align-items: center;
}
#newsletter span{
color: #b5042e;
}
#newsletter input{
    display: block;
    height: 3.125rem;
    padding: 0 2.25rem;
    width: 100%;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
}
.form{
    display: flex;
    align-items: center;

}
#newsletter button{
    display: block;
    background-color: #b5042e;
    color: #bfb4b3;
    margin-top: 20px;
    margin-left: 20px;
}
footer{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}
footer .col{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
}
footer h4{
    font-size: 25px;
    padding-bottom: 20px;
}
footer p{
    font-size: 15px;
    margin:  0 0 8px 0;
}
footer a{
    font-size: 15px;
    text-decoration: none;
    color:rgba(224,214, 211, 0.3);
    margin:  0 0 8px 0;
}
footer .follow{
    margin-top: 20px;
}
footer .follow i{
    color: #dfe0fe;
    padding-right: 20px;
    cursor: pointer;
}
footer .install img{
    margin: 20px 0;
}
footer .follow i:hover {
    color: #b3a99c
}
footer .copyright{
    width: 100%;
    text-align: center;
}

/* carousel starts from here */

.carousel-item{
    height: 100vh;
    margin-bottom: 150px;
    min-height: 300px;
    background: no-repeat scroll center scroll;
    background-size: cover;
}
.carousel-item::before{
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    /* background: #000 */
    opacity:0.7;
}
.carousel-caption{
    bottom:180px;
    padding-left: 100px;
    padding-right: 100px;
}
.carousel-caption h5{
    color: #ddbf82;
    font-size: 100px;
    font-weight: 700;
}
.carousel-caption h4{
    color: white;
    font-size: 100px;
    font-weight: 700;
}
.carousel-caption p{
    font-size: 18px;
    top: 2 rem;
}
.slider-btn{
    margin-top: 30px;
}
.slider-btn .btn{
    background-color:  #ddbf82;
    color: white;
    padding: 1.5rem 2rem;
    font-size: 1rem;
}
.backVideo{
    padding: 0 0%;
    position: absolute;
    /* top: 10;
    left: 0; */
    bottom: 0;
    right: 0;
    z-index: -1;
}
.content{
    text-align: center;
    position: absolute;
    right: 0%;
    bottom: 0;
}
.Shop{
    width: 100%;
    height: 105vh;
    position: relative;
    padding: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.btnShop{
    margin-top: 350px;
    font-size: 35px;
    background-color: rgba(255, 255, 255, 0.911);
    color: #333;
    border:2px solid white;
    border-radius: 50px;
    overflow: hidden;
    padding:14px 60px 14px 60px ;
    cursor: pointer;
    font-weight: 700;
    transition: .2s ease;
}
.btnShop:hover{
    transform: scale(1.04);
    color: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.h1Head{
    font-size: 75px;
    color: white;
}
.shop-items{
    margin-top: 200px;
    margin-bottom: 250px;
    /* height: 100vh; */
    width: 100%;
    background-position: top 10% right 0%;
    padding:0px 80px;
    display: flex;
    flex-direction: column;
    align-items:flex-start;
    justify-content: center;
}
.center{
    height: 80vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:space-evenly;
}
.shop-items .space{
    height: 50vh;
}
.shop-items h1{
    margin-left: 100px;
    color: #dfe0fe;
    font-size: 100px;
    font-family:'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif ;
    margin-bottom: 50px;
}
.shop-items  p{
    /* text-align: center; */
    margin-top: 30px;
    margin-left: 100px;
    margin-bottom: 50px;
    font-size: 30px;
    color: #e8f6ea;
}
.shop-items  h3{
    margin-left: 100px;
    font-size: 20px;
    color: #e8f6ea;
}
.shop-items  h4{
    width: 100%;
    text-align: center; 
    font-size: 150px;
    color: #e8f6ea;
}
.shop-items  span{
    color: rgba(255, 255, 255, 0.795);
    transition: .7s ease;
}
.shop-items  span:hover{
    color: white;
    text-shadow: 0 0 20px #fff, 0 0 30px #fff, 0 0 40px #fff, 0 0 50px #fff, 0 0 60px #fff, 0 0 70px #fff, 0 0 80px #fff;
}


.shop-items-page{
    position: relative;
    background-image: url("../images/lebron-17.jpg");
    background-size: cover;
    height: 110vh;
    width: 100%;
    background-position: top 10% right 0%;
    /* padding:80px 80px; */
}
/* .headh3{
    position: absolute;
    bottom: -6%;
    right: 0;
    color: #333;
    z-index: 1;
    font-size: 10em;
    white-space: nowrap;                    
    font-family: 'Righteous', cursive;
    font-weight: 100;
} */
/* Textwrap nowrap so that the text doesnt break into multiple lines */
.head{
    position: absolute;
    bottom: 8%;
    left: 0;
    color: white;
    z-index: 1;
    font-size: 25em;
    white-space: nowrap;                    
    font-family: 'Righteous', cursive;
    font-weight: 400;
}
.comma{
    color: #333;
}
.imgHover{
    margin: 100px 20px;
    height: 100px;
}
.imgHover:hover {
    animation: shake 0.5s infinite;
}

                            /* Hz scrolling starts from here  */
.Hz{
    background-color: black;
    width: 100vw;
    height: 112vh;
}
.main{
    width: 100vw;
    height: 100vh;
}


.container {  
    margin: 5px 0px;
    display: grid;
    width: 100%;
    height: 105vh;
    width: auto;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    gap: 0px 5px;
    grid-auto-flow: column;
    grid-template-areas:
      "big big big big big smol-1 smol-1 smol-1"
      "big big big big big smol-2 smol-2 smol-2";
  }
  
.container2 {  
    display: block;
    padding: 0 13%;
    background-color: #fff;
  }
.row1{
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 20px;
}
  .big { 
    grid-area: big; 
    background-image: url("https://cdn.shopify.com/s/files/1/0659/0722/8923/files/7_2.png?v=1679574832"); 
    background-size: 1000px 800px; 
    background-repeat: no-repeat; 
}
  
  .smol-1 { 
    grid-area: smol-1; 
    background-image: url("https://cdn.shopify.com/s/files/1/0659/0722/8923/files/NIKE_AIR_MAX.png?v=1679652828"); 
    background-size:cover ; 
    background-repeat: no-repeat;     
}
  
  .smol-2 { 
    grid-area: smol-2; 
    background-image: url("https://cdn.shopify.com/s/files/1/0659/0722/8923/files/RIGHT_BANNER_-_BOTTOM.png?v=1679314749"); 
    background-size: cover ; 
    background-repeat: no-repeat;
}
.container4{
    width: 100%;
}
.eyel {
    display: inline-block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background: #ffffffd6;
    position: absolute;
  right: 148px;
  top: 120px;
  z-index: 2;
  }
  .eyel:after { /*pupil*/
    position: absolute;
    bottom: 17px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: #000;
    border-radius: 50%;
    content: " ";
  }
  .eyer {
    display: inline-block;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    background: #ffffffd6;
    position: absolute;
  left: 148px;
  top: 120px;
  z-index: 2;
  }
  .eyer:after { /*pupil*/
    position: absolute;
    bottom: 17px;
    right: 10px;
    width: 10px;
    height: 10px;
    background: #000;
    border-radius: 50%;
    content: " ";
  }
  .move-area{
      width: 105vw;
      height: 50vh;
      padding: 4% 45%;
    }
/* Bo face */
.bo-happy{
    font-family: 'Miteria';
    font-size: 30px;
    line-height: 50px;
}
.bo-happy span{
    font-family: 'Miteria';
    color: #eb0e2f;

}
.face {
  position: relative;
  width: 300px;
  height: 350px;
  background-color: #6C757D;
  background: linear-gradient(180deg,#452824 184px,#f2bbad 34px);
    /* transform: translateX(0px); */
  border-top-right-radius: 250px 350px;
  border-top-left-radius: 250px 350px;
  border-bottom-left-radius: 250px 250px;
  border-bottom-right-radius: 250px 250px;
  border: 5px solid black;
  z-index: 5;
}

.hairs {
  height: 180px;
  background-color: #f2bbad;
  position: absolute;
  width: 290px;
  border-top-right-radius: 250px 350px;
  border-top-left-radius: 250px 350px;
  top: 25px;
}

.snow {
  transform: translateX(150px);
  transform-origin: right;
}

.mountain-cap-1, .mountain-cap-2, .mountain-cap-3,.mountain-cap-4 {
  position: absolute;
  top: 20px;
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
  border-top: 40px solid #452824;
}

.mountain-cap-1 {
  left: -65px;
}

.mountain-cap-2 {
  left: -35px;
}

.mountain-cap-3 {
  left: -5px;
}

.mountain-cap-4 {
  left: 25px;
}

.earL {
  border-top-right-radius: 250px 350px;
  border-top-left-radius: 250px 350px;
  border-bottom-left-radius: 250px 250px;
  border-bottom-right-radius: 250px 250px;
  height: 70px;
  width: 50px;
  background-color: #f2bbad;
  position: absolute;
  left: -30px;
  top: 150px;
  /* z-index: -1; */
  border: 5px solid black;
}

.earR {
  border-top-right-radius: 250px 350px;
  border-top-left-radius: 250px 350px;
  border-bottom-left-radius: 250px 250px;
  border-bottom-right-radius: 250px 250px;
  height: 70px;
  width: 50px;
  background-color: #f2bbad;
  position: absolute;
  right: -30px;
  top: 150px;
  /* z-index: -4; */
  border: 5px solid black;
}

.eyeR {
  border-top-right-radius: 250px 350px;
  border-top-left-radius: 250px 350px;
  border-bottom-left-radius: 250px 250px;
  border-bottom-right-radius: 250px 250px;
  height: 10px;
  width: 10px;
  background-color: black;
  position: absolute;
  left: 128px;
  top: 120px;
  z-index: 2;
  animation: blinkR 5s infinite linear;
}

.eyeL {
  border-top-right-radius: 250px 350px;
  border-top-left-radius: 250px 350px;
  border-bottom-left-radius: 250px 250px;
  border-bottom-right-radius: 250px 250px;
  height: 10px;
  width: 10px;
  background-color: black;
  position: absolute;
  right: 128px;
  top: 120px;
  z-index: 2;
  animation: blinkL 5s infinite linear;
}

.nose {
  background: #fefefe;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  color: #0F0;
  position: absolute;
  top: 220px;
  left: 140px;
  scale: 1 1;
  transition: all ease-in-out .3s;
  z-index: 100;
}

.nose:hover {
  scale: 1 1.25;
  top: 236px;
}

.nose:active {
  animation: propeller .5s infinite linear;
  transform-origin: 50% -55px;
  scale: 1 1.25;
  top: 220px;
  animation-delay: .5s;
}

@keyframes propeller {
  to {
    transform: rotateZ(360deg);
  }
}

@keyframes blinkL {
  98% {
    height: 10px;
    width: 10px;
    right: 128px;
    top: 120px;
  }

  100% {
    height: 3px;
    width: 11px;
    right: 126px;
    top: 123px;
  }
}

@keyframes blinkR {
  98% {
    height: 10px;
    width: 10px;
    left: 128px;
    top: 120px;
  }

  100% {
    height: 3px;
    width: 11px;
    left: 126px;
    top: 123px;
  }
}

.nose:before {
  content: "";
  position: absolute;
  top: -64px;
  left: 50%;
  border: 10px solid transparent;
  border-bottom: 62px solid #fefefe;
  transform: translateX(-50%);
}

.mouth {
  position: absolute;
  top: 300px;
  left: 140px;
  background: #a36655;
  width: 25px;
  height: 18px;
  border: 5px solid black;
  border-radius: 50%;
  transition: all .3s linear;
}

.face:active .mouth {
  top: 290px;
  width: 60px;
  height: 25px;
  left: 123px;
  border-radius: 7px 7px 130px 130px;
}

.face:active .smileL {
  height: 30px;
  width: 30px;
}

.face:active .smileR {
  height: 30px;
  width: 30px;
}

.bo {
  z-index: 10;
  zoom: 0.8;
}

.smileL {
  position: absolute;
  height: 0px;
  width: 0px;
  rotate: 310deg;
  top: 180px;
  left: 70px;
  transition: all .1s linear;
}

.smileR {
  position: absolute;
  height: 0px;
  width: 0px;
  rotate: 310deg;
  top: 180px;
  right: 70px;
  transition: all .1s linear;
}





























@media (min-aspect-ratio : 16/9){
    .backVideo{
        width: 100%;
        height: auto;
    }
}
@media (max-aspect-ratio : 16/9){
    .backVideo{
        width: auto;
        height: 80%;
    }
}