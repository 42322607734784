.bigContainer{
    width: 100%;
    position: relative;
    padding: 0 5%;
}
    /* CARD CSS */
.bigCard{
    display: inline-block;
}
.moreCards{
    margin: 0 20px;
    
}
.product-card {
    display: block;
    flex: 0.3;
    width: 400px;
    position: relative;
    margin: 50px 10%;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    /* box-shadow: 3px 3px pink; */
    transition: all 0.3s cubic-bezier(.25,.8,.25,1);
}

.product-tumb {
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
    /* padding: 50px; */
    background: #F7F7F7;
}

.product-tumb img {
    /* background-size: cover; */
    max-width: 100%;
    max-height: 100%;
}

.product-details {
    padding: 30px;
    border: 1px solid black;
    border-radius: 10px 10px 0 0;
}

.product-catagory {
    display: block;
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    color: #ccc;
    margin-bottom: 18px;
}

.product-details h4 a {
    font-weight: 500;
    display: block;
    margin-bottom: 18px;
    text-transform: uppercase;
    color: #000;
    text-decoration: none;
    transition: 0.3s;
}

.product-details h4 a:hover {
    color: #958a88;
}

.product-details p {
    font-size: 15px;
    line-height: 22px;
    margin-bottom: 18px;
    color: #000;
}

.product-bottom-details {
    overflow: hidden;
    border-top: 1px solid #eee;
    padding-top: 20px;
}

.product-bottom-details div {
    float: left;
    width: 50%;
}

.product-price {
    margin-top: 5px;
    font-size: 18px;
    color: #000;
    font-weight: 600;
}
.product-price small {
    font-size: 80%;
    font-weight: 400;
    text-decoration: line-through;
    display: inline-block;
    margin-right: 5px;
}

.product-links {
    text-align: right;
}

.product-links a {
    display: inline-block;
    margin-left: 5px;
    color: #e1e1e1;
    transition: 0.3s;
    font-size: 17px;
}

.product-links a:hover {
    color: #958a88;
}
.container1 {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 2%;
    margin-bottom: -1%;
    width: 100%;
    padding: 20px;
    /* background-color: #fff; */
  }
  
  .row {
    display: flex;
    flex-wrap: wrap;
  }
  
  .col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
.breadcrumb {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumb-item {
    margin-right: 10px;
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: #555;
  }
  
  .breadcrumb-item.active {
    color: #e1e1e1;
  }
  
  .fa-home {
    margin-right: 5px;
  }
/* shoeDetails component css  */
.parent{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 2fr);
    grid-column-gap: 20px;
    grid-row-gap: 20px;

}
.tcCenter{
    font-size: 30px;
    font-family: 'Genos';
    /* margin: 50px auto; */
    text-align: center;
    color: #e1e1e1;
}
.tcCenter1{
    font-size: 30px;
    font-family: 'Genos';
    /* margin: 50px auto; */
    text-align: center;
    color: #000;
}
.twoFlex{
    /* padding:0 2%; */ 
    margin-bottom: 5%;
    display: flex;
    justify-content: space-between;
}
.spacey{
    margin: 0 30px;
}
.spacey-bw{
    margin: 0 10px;
    border-radius: 15px;
}
.images-section {
    margin-top: 5%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .main-image{
    width: 500px;
    height: 500px;
    border-radius: 15px;
  }
  .main-image img {
    width: 500px;
    height: 500px;
  }
  
  .small-images {
    display: flex;
    justify-content: space-between;
  }
  
  .small-images img {
    width: 150px;
    height: 150px;
  }
.articles-desc{
    margin-top: 70px;
    padding: 0 50px;
    display: flex;
    flex: 0.4;
    flex-direction: column;
    justify-content: space-between;
    height: auto;
}
.checkbox{
    background-color: #000;
  color: #fff;
  border: 1px solid #fff;
  padding: 10px 20px;
  margin: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
.checkbox:hover{
    background-color: #5aaffa;
}
.checkbox.selected {
    background-color: #5aaffa;
  }
.add-to-cart{
    /* margin-top: 30px; */
    background: #000;
    color: #fff;
    height: 70px;
    width: 500px;
    border-radius: 12px;
    border: 1px solid #fff;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition-duration: .5s;
    overflow: hidden;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.103);
    position: relative;
}
.text1 {
    height: 100%;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    z-index: 1;
    transition-duration: .5s;
    font-size: 30px;
    font-weight: 600;
  }
.IconContainer {
    position: absolute;
    left: -50px;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    z-index: 2;
    transition-duration: .5s;
    font-size: 30px;
  }
  .add-to-cart:hover {
      background-color: #5aaffa;
    border: none;
  }
  .add-to-cart:hover .IconContainer {
    transform: translateX(158px);
    border-radius: 40px;
    transition-duration: .5s;
  }
  .CartBtn:hover .text {
    transform: translateX(30px);
    transition-duration: .5s;
  }
  .CartBtn:active {
    transform: scale(0.95);
    transition-duration: .5s;
  }
.genos{
    font-family: 'Genos';
    font-size: 24px;
    color: #fff;
    text-align: center;

}
.vendor{
    font-style: italic;
    font-size: 30px;
    color: #fff;    
}
.vendor-name{
    padding: 20px 0;
}
.descc{
    margin: 10px 0;
    font-size: 18px;
    color: #fff;
}
.flx-wave{
    display: flex;
    justify-content: space-around;
    align-items: center;    
}
.flex-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
.brand-logo{
    font-size: 54px;
}

.redirect{
    background-color: white;
  color: black;
  border-radius: 10em;
  font-size: 17px;
  font-weight: 600;
  padding: 1em 2em;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 1px solid black;
  box-shadow: 0 0 0 0 black;
}

.redirect:hover{
    transform: translateY(-4px) translateX(-2px);
    box-shadow: 2px 5px 0 0 black;
}

.redirect:active{
    transform: translateY(-2px) translateX(-1px);
    box-shadow: 0 0 0 0 black;
}
.quantity-buttons{
    font-size: 40px;
    height: 50px;
    border: 2px solid rgba(255, 255, 255, 0.8);
    display: flex;
    width: fit-content;
}
.quantity-buttons span{
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 40px;
    color: white;
}
.quantity-buttons span:nth-child(1){
    border-right: 2px solid rgba(255, 255, 255, 0.8);   
}
.quantity-buttons span:nth-child(2){
    width: 60px;
}
.quantity-buttons span:nth-child(3){
    border-left: 2px solid rgba(255, 255, 255, 0.8);   
}
.related-products{
    width: 100vw;
    display:flex;
    margin-left: 100px;
    align-items: center;

}
.btnShop1{
    margin-left: 600px;
    font-size: 35px;
    background-color: rgba(255, 255, 255, 0.911);
    color: #333;
    border:2px solid white;
    border-radius: 50px;
    overflow: hidden;
    padding:14px 40px 14px 40px ;
    cursor: pointer;
    font-weight: 700;
    transition: .2s ease;
}
.btnShop1:hover{
    transform: scale(1.04);
    color: white;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}
.cart-footer{
    border-top: 1px solid #ccc;
}
.sub-total{
    padding: 40px 30px;
    border-bottom: 1px solid #ccc;
    display: flex;
    justify-content: space-around;
}
.text{
    font-size: 40px;
    color: #ccc;
}
.total{
    color: peru;
}



/* 3 dots loading animation */
.loading {
	opacity: 0;
	display: flex;
	position: fixed;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	transition: opacity .3s ease-in;
    margin-top: 30px;
}

.loading.show {
	opacity: 1;
}


/* loading hamster css  */

.wheel-and-hamster {
    --dur: 1s;
    position: relative;
    width: 12em;
    height: 12em;
    font-size: 14px;
  }
  
  .wheel,
  .hamster,
  .hamster div,
  .spoke {
    position: absolute;
  }
  
  .wheel,
  .spoke {
    border-radius: 50%;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  
  .wheel {
    background: radial-gradient(100% 100% at center,hsla(0,0%,60%,0) 47.8%,hsl(0,0%,60%) 48%);
    z-index: 2;
  }
  
  .hamster {
    animation: hamster var(--dur) ease-in-out infinite;
    top: 50%;
    left: calc(50% - 3.5em);
    width: 7em;
    height: 3.75em;
    transform: rotate(4deg) translate(-0.8em,1.85em);
    transform-origin: 50% 0;
    z-index: 1;
  }
  
  .hamster__head {
    animation: hamsterHead var(--dur) ease-in-out infinite;
    background: hsl(30,90%,55%);
    border-radius: 70% 30% 0 100% / 40% 25% 25% 60%;
    box-shadow: 0 -0.25em 0 hsl(30,90%,80%) inset,
          0.75em -1.55em 0 hsl(30,90%,90%) inset;
    top: 0;
    left: -2em;
    width: 2.75em;
    height: 2.5em;
    transform-origin: 100% 50%;
  }
  
  .hamster__ear {
    animation: hamsterEar var(--dur) ease-in-out infinite;
    background: hsl(0,90%,85%);
    border-radius: 50%;
    box-shadow: -0.25em 0 hsl(30,90%,55%) inset;
    top: -0.25em;
    right: -0.25em;
    width: 0.75em;
    height: 0.75em;
    transform-origin: 50% 75%;
  }
  
  .hamster__eye {
    animation: hamsterEye var(--dur) linear infinite;
    background-color: hsl(0,0%,0%);
    border-radius: 50%;
    top: 0.375em;
    left: 1.25em;
    width: 0.5em;
    height: 0.5em;
  }
  
  .hamster__nose {
    background: hsl(0,90%,75%);
    border-radius: 35% 65% 85% 15% / 70% 50% 50% 30%;
    top: 0.75em;
    left: 0;
    width: 0.2em;
    height: 0.25em;
  }
  
  .hamster__body {
    animation: hamsterBody var(--dur) ease-in-out infinite;
    background: hsl(30,90%,90%);
    border-radius: 50% 30% 50% 30% / 15% 60% 40% 40%;
    box-shadow: 0.1em 0.75em 0 hsl(30,90%,55%) inset,
          0.15em -0.5em 0 hsl(30,90%,80%) inset;
    top: 0.25em;
    left: 2em;
    width: 4.5em;
    height: 3em;
    transform-origin: 17% 50%;
    transform-style: preserve-3d;
  }
  
  .hamster__limb--fr,
  .hamster__limb--fl {
    clip-path: polygon(0 0,100% 0,70% 80%,60% 100%,0% 100%,40% 80%);
    top: 2em;
    left: 0.5em;
    width: 1em;
    height: 1.5em;
    transform-origin: 50% 0;
  }
  
  .hamster__limb--fr {
    animation: hamsterFRLimb var(--dur) linear infinite;
    background: linear-gradient(hsl(30,90%,80%) 80%,hsl(0,90%,75%) 80%);
    transform: rotate(15deg) translateZ(-1px);
  }
  
  .hamster__limb--fl {
    animation: hamsterFLLimb var(--dur) linear infinite;
    background: linear-gradient(hsl(30,90%,90%) 80%,hsl(0,90%,85%) 80%);
    transform: rotate(15deg);
  }
  
  .hamster__limb--br,
  .hamster__limb--bl {
    border-radius: 0.75em 0.75em 0 0;
    clip-path: polygon(0 0,100% 0,100% 30%,70% 90%,70% 100%,30% 100%,40% 90%,0% 30%);
    top: 1em;
    left: 2.8em;
    width: 1.5em;
    height: 2.5em;
    transform-origin: 50% 30%;
  }
  
  .hamster__limb--br {
    animation: hamsterBRLimb var(--dur) linear infinite;
    background: linear-gradient(hsl(30,90%,80%) 90%,hsl(0,90%,75%) 90%);
    transform: rotate(-25deg) translateZ(-1px);
  }
  
  .hamster__limb--bl {
    animation: hamsterBLLimb var(--dur) linear infinite;
    background: linear-gradient(hsl(30,90%,90%) 90%,hsl(0,90%,85%) 90%);
    transform: rotate(-25deg);
  }
  
  .hamster__tail {
    animation: hamsterTail var(--dur) linear infinite;
    background: hsl(0,90%,85%);
    border-radius: 0.25em 50% 50% 0.25em;
    box-shadow: 0 -0.2em 0 hsl(0,90%,75%) inset;
    top: 1.5em;
    right: -0.5em;
    width: 1em;
    height: 0.5em;
    transform: rotate(30deg) translateZ(-1px);
    transform-origin: 0.25em 0.25em;
  }
  
  .spoke {
    animation: spoke var(--dur) linear infinite;
    background: radial-gradient(100% 100% at center,hsl(0,0%,60%) 4.8%,hsla(0,0%,60%,0) 5%),
          linear-gradient(hsla(0,0%,55%,0) 46.9%,hsl(0,0%,65%) 47% 52.9%,hsla(0,0%,65%,0) 53%) 50% 50% / 99% 99% no-repeat;
  }
  
  /* Animations */
  @keyframes hamster {
    from, to {
      transform: rotate(4deg) translate(-0.8em,1.85em);
    }
  
    50% {
      transform: rotate(0) translate(-0.8em,1.85em);
    }
  }
  
  @keyframes hamsterHead {
    from, 25%, 50%, 75%, to {
      transform: rotate(0);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(8deg);
    }
  }
  
  @keyframes hamsterEye {
    from, 90%, to {
      transform: scaleY(1);
    }
  
    95% {
      transform: scaleY(0);
    }
  }
  
  @keyframes hamsterEar {
    from, 25%, 50%, 75%, to {
      transform: rotate(0);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(12deg);
    }
  }
  
  @keyframes hamsterBody {
    from, 25%, 50%, 75%, to {
      transform: rotate(0);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(-2deg);
    }
  }
  
  @keyframes hamsterFRLimb {
    from, 25%, 50%, 75%, to {
      transform: rotate(50deg) translateZ(-1px);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(-30deg) translateZ(-1px);
    }
  }
  
  @keyframes hamsterFLLimb {
    from, 25%, 50%, 75%, to {
      transform: rotate(-30deg);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(50deg);
    }
  }
  
  @keyframes hamsterBRLimb {
    from, 25%, 50%, 75%, to {
      transform: rotate(-60deg) translateZ(-1px);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(20deg) translateZ(-1px);
    }
  }
  
  @keyframes hamsterBLLimb {
    from, 25%, 50%, 75%, to {
      transform: rotate(20deg);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(-60deg);
    }
  }
  
  @keyframes hamsterTail {
    from, 25%, 50%, 75%, to {
      transform: rotate(30deg) translateZ(-1px);
    }
  
    12.5%, 37.5%, 62.5%, 87.5% {
      transform: rotate(10deg) translateZ(-1px);
    }
  }
  
  @keyframes spoke {
    from {
      transform: rotate(0);
    }
  
    to {
      transform: rotate(-1turn);
    }
  }