@font-face {
  font-family: 'Miteria';
  src: url('../Mitera Regular.otf') format('woff2');
}
.mt-15 {
  margin-top: 15px !important; }

.mt-30 {
  margin-top: 30px !important; }

.mt-50 {
  margin-top: 50px !important; }

.mt-70 {
  margin-top: 70px !important; }

.mt-100 {
  margin-top: 100px !important; }

.mb-15 {
  margin-bottom: 15px !important; }

.mb-30 {
  margin-bottom: 30px !important; }

.mb-50 {
  margin-bottom: 50px !important; }

.mb-70 {
  margin-bottom: 70px !important; }

.mb-100 {
  margin-bottom: 100px !important; }

.ml-15 {
  margin-left: 15px !important; }

.ml-30 {
  margin-left: 30px !important; }

.ml-50 {
  margin-left: 50px !important; }

.mr-15 {
  margin-right: 15px !important; }

.mr-30 {
  margin-right: 30px !important; }

.mr-50 {
  margin-right: 50px !important; }

/* :: 3.2.0 Height */
.height-400 {
  height: 400px; }

.height-500 {
  height: 500px; }

.height-600 {
  height: 600px; }

.height-700 {
  height: 700px; }

.height-800 {
  height: 800px; }

/* .btnhover::after{
  content: "Please Login to add to cart";
} */

/* :: 3.3.0 Section Padding */
.section-padding-100 {
  padding-top: 100px;
  padding-bottom: 100px; }

.section-padding-100-0 {
  padding-top: 100px;
  padding-bottom: 0; }

.section-padding-0-100 {
  padding-top: 0;
  padding-bottom: 100px; }

.section-padding-100-70 {
  padding-top: 100px;
  padding-bottom: 70px; }

/* :: 3.4.0 Section Heading */
.section-heading {
  position: relative;
  z-index: 1;
  margin-bottom: 40px; }
  .section-heading h2 {
    font-size: 30px;
    text-transform: uppercase;
    margin-bottom: 0; }
    @media only screen and (max-width: 767px) {
      .section-heading h2 {
        font-size: 24px; } }
  .section-heading p {
    font-size: 16px;
    color: #707070;
    margin-bottom: 0; }

/* :: 3.5.0 Preloader */
.preloader {
  background-color: #f2f4f5;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999;
  overflow: hidden; }

  .empty-cart{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 100px;
    
  }
.empty-cart svg{
  font-size: 120px;
  opacity: 0.1;
  
}
.cart-items {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.cart-item {
  display: flex;
  padding: 20px;
  border-bottom: 1px solid #ddd;
  align-items: center;
  margin-bottom: 20px;
}

.cart-item:last-child {
  border-bottom: none;
}
.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.cart-item-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.cart-item-name {
  font-size: 18px;
  font-weight: bold;
}

.cart-item-price {
  color: #333;
}

.cart-item-quantity {
  color: #666;
}
.cart-items {
  /* display: flex;
  flex-direction: column;
  gap: 20px; */
  margin-top: 20px;
  padding:5% 10% ;
}

.cart_product_img h5 {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.qty {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qty .quantity {
  display: flex;
  align-items: center;
}

.qty-minus,
.qty-plus {
  cursor: pointer;
  padding: 5px 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.qty-text {
  margin: 0 10px;
}

.price,
.total_price {
  font-size: 16px;
  font-weight: bold;
  color: #333;
}

.action .close-btn {
  cursor: pointer;
  color: #ff0000;
}
.cart-item-image {
  width: 100px;
  height: 100px;
  object-fit: cover;
}
.table {
  width: 100%;
  margin-top: 20px;
  border-collapse: collapse;
}

.table th,
.table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}

.table th {
  background-color: #f5f5f5;
  color: #333;
}

.table tbody tr:last-child td {
  border-bottom: none;
}
  .preloader .preloader-circle {
    width: 80px;
    height: 80px;
    position: relative;
    border-style: solid;
    border-width: 2px;
    border-top-color: #70c745;
    border-bottom-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    z-index: 10;
    border-radius: 50%;
    box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.15);
    background-color: #ffffff;
    -webkit-animation: zoom 2000ms infinite ease;
    animation: zoom 2000ms infinite ease; }
  .preloader .preloader-img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    z-index: 200; }
    .preloader .preloader-img img {
      max-width: 45px; }

@-webkit-keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
@keyframes zoom {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }
/* :: 3.6.0 Miscellaneous */
.bg-img {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat; }

.bg-white {
  background-color: #ffffff !important; }

.bg-dark {
  background-color: #000000 !important; }

.bg-transparent {
  background-color: transparent !important; }

.font-bold {
  font-weight: 700; }

.font-light {
  font-weight: 300; }

.bg-overlay {
  position: relative;
  z-index: 2;
  background-position: center center;
  background-size: cover; }
  .bg-overlay::after {
    background-color: rgba(17, 17, 17, 0.5);
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: ""; }

.bg-fixed {
  background-attachment: fixed !important; }

.bg-gray {
  background-color: #f2f4f5; }

/* :: 3.7.0 ScrollUp */
#scrollUp {
  background-color: #70c745;
  border-radius: 0;
  bottom: 0;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.3);
  color: #ffffff;
  font-size: 24px;
  height: 40px;
  line-height: 40px;
  right: 50px;
  text-align: center;
  width: 40px;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms; }
  #scrollUp:hover {
    background-color: #303030; }

/* :: 3.8.0 alazea Button */
.alazea-btn {
  -webkit-transition-duration: 400ms;
  transition-duration: 400ms;
  position: relative;
  z-index: 1;
  display: inline-block;
  min-width: 150px;
  height: 46px;
  color: #ffffff;
  background-color: #70c745;
  border: 2px solid #70c745;
  border-radius: 2px;
  padding: 0 20px;
  font-size: 16px;
  line-height: 42px;
  text-transform: uppercase;
  font-weight: 600; }
  .alazea-btn.active, .alazea-btn:hover, .alazea-btn:focus {
    font-size: 16px;
    color: #70c745;
    font-weight: 600;
    background-color: transparent;
    box-shadow: none; }

/* :: 4.0 Header Area CSS */
.header-area {
  position: absolute;
  width: 100%;
  z-index: 999;
  top: 0;
  left: 0;
  background-color: transparent; }
  .header-area .top-header-area {
    position: relative;
    z-index: 100;
    background-color: transparent;
    width: 100%;
    height: 42px;
    border-bottom: 1px solid rgba(235, 235, 235, 0.2); }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-area .top-header-area {
        z-index: 1; } }
    @media only screen and (max-width: 767px) {
      .header-area .top-header-area {
        z-index: 1; } }
    .header-area .top-header-area .top-header-content {
      position: relative;
      z-index: 1;
      height: 41px; }
      .header-area .top-header-area .top-header-content .top-header-meta a {
        display: inline-block;
        font-size: 12px;
        font-weight: 400;
        color: #ffffff;
        line-height: 1; }
        .header-area .top-header-area .top-header-content .top-header-meta a:first-child {
          margin-right: 30px; }
        .header-area .top-header-area .top-header-content .top-header-meta a i {
          margin-right: 5px;
          color: #70c745; }
        @media only screen and (max-width: 767px) {
          .header-area .top-header-area .top-header-content .top-header-meta a span {
            display: none; } }
      .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown {
        position: relative;
        z-index: 1; }
        .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown::after {
          width: 1px;
          height: 100%;
          background-color: rgba(235, 235, 235, 0.2);
          content: '';
          top: 0;
          right: 15px;
          z-index: 2;
          position: absolute; }
        .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown .btn {
          padding: 0;
          background-color: transparent;
          border: none;
          font-size: 12px; }
          .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown .btn:focus {
            box-shadow: none; }
        .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown .dropdown-menu {
          background-color: #70c745;
          border: none;
          box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15); }
          @media only screen and (min-width: 768px) and (max-width: 991px) {
            .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown .dropdown-menu {
              top: 90px !important; } }
          @media only screen and (max-width: 767px) {
            .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown .dropdown-menu {
              min-width: 100px;
              top: 70px !important; } }
          .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown .dropdown-menu .dropdown-item:focus,
          .header-area .top-header-area .top-header-content .top-header-meta .language-dropdown .dropdown-menu .dropdown-item:hover {
            color: #ffffff;
            background-color: #111111; }
      .header-area .top-header-area .top-header-content .top-header-meta .cart {
        position: relative;
        z-index: 1; }
        .header-area .top-header-area .top-header-content .top-header-meta .cart::after {
          width: 1px;
          height: 100%;
          background-color: rgba(235, 235, 235, 0.2);
          content: '';
          top: 0;
          left: -15px;
          z-index: 2;
          position: absolute; }
        .header-area .top-header-area .top-header-content .top-header-meta .cart a {
          margin-right: 0; }
  .header-area .alazea-main-menu {
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 200ms;
    transition-duration: 200ms; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-area .alazea-main-menu .classy-navbar .classy-menu {
        background-color: #111111; } }
    @media only screen and (max-width: 767px) {
      .header-area .alazea-main-menu .classy-navbar .classy-menu {
        background-color: #111111; } }
    .header-area .alazea-main-menu .classy-nav-container {
      background-color: transparent; }
    .header-area .alazea-main-menu .classy-navbar {
      height: 90px;
      padding: 0; }
      .header-area .alazea-main-menu .classy-navbar .nav-brand {
        line-height: 1; }
      @media only screen and (max-width: 767px) {
        .header-area .alazea-main-menu .classy-navbar {
          height: 70px; } }
    .header-area .alazea-main-menu .classynav ul li a {
      padding: 0 30px;
      font-weight: 500;
      text-transform: capitalize;
      font-size: 20px;
      color: #ffffff; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .header-area .alazea-main-menu .classynav ul li a {
          font-size: 18px;
          padding: 0 20px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .header-area .alazea-main-menu .classynav ul li a {
          background-color: #111111;
          font-size: 16px;
          color: #ffffff;
          border-bottom: none; } }
      @media only screen and (max-width: 767px) {
        .header-area .alazea-main-menu .classynav ul li a {
          background-color: #111111;
          font-size: 16px;
          color: #ffffff;
          border-bottom: none; } }
      .header-area .alazea-main-menu .classynav ul li a:hover, .header-area .alazea-main-menu .classynav ul li a:focus {
        color: #70c745; }
      .header-area .alazea-main-menu .classynav ul li a::after {
        color: #ffffff; }
    .header-area .alazea-main-menu .classynav ul li ul li a {
      padding: 0 20px;
      color: #303030;
      font-size: 14px;
      border-bottom: none; }
      .header-area .alazea-main-menu .classynav ul li ul li a::after {
        color: #303030; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .header-area .alazea-main-menu .classynav ul li ul li a::after {
            color: #ffffff; } }
        @media only screen and (max-width: 767px) {
          .header-area .alazea-main-menu .classynav ul li ul li a::after {
            color: #ffffff; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .header-area .alazea-main-menu .classynav ul li ul li a {
          padding: 0 45px;
          color: #ffffff; } }
      @media only screen and (max-width: 767px) {
        .header-area .alazea-main-menu .classynav ul li ul li a {
          padding: 0 45px;
          color: #ffffff; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-area .alazea-main-menu .classy-navbar-toggler .navbarToggler span {
        background-color: #ffffff; } }
    @media only screen and (max-width: 767px) {
      .header-area .alazea-main-menu .classy-navbar-toggler .navbarToggler span {
        background-color: #ffffff; } }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-area .alazea-main-menu .classycloseIcon .cross-wrap span {
        background: #ffffff; } }
    @media only screen and (max-width: 767px) {
      .header-area .alazea-main-menu .classycloseIcon .cross-wrap span {
        background: #ffffff; } }
    .header-area .alazea-main-menu .search-form {
      position: relative;
      z-index: 1;
      opacity: 0;
      visibility: hidden;
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms; }
      .header-area .alazea-main-menu .search-form form {
        -webkit-transition-duration: 500ms;
        transition-duration: 500ms;
        position: absolute;
        top: 0;
        right: 0;
        z-index: 100;
        background-color: #ffffff;
        width: 500px;
        border-radius: 5px;
        padding: 30px;
        box-shadow: 0 3px 40px 0 rgba(0, 0, 0, 0.15); }
        @media only screen and (max-width: 767px) {
          .header-area .alazea-main-menu .search-form form {
            width: 290px;
            padding: 20px; } }
        .header-area .alazea-main-menu .search-form form input {
          width: 100%;
          height: 45px;
          border: 1px solid #ebebeb;
          padding: 0 30px;
          border-radius: 5px;
          font-size: 14px; }
          @media only screen and (max-width: 767px) {
            .header-area .alazea-main-menu .search-form form input {
              padding: 0 15px; } }
      .header-area .alazea-main-menu .search-form .closeIcon {
        -webkit-transition-duration: 500ms;
        transition-duration: 500ms;
        position: absolute;
        top: 41px;
        right: 60px;
        z-index: 200;
        cursor: pointer;
        color: #707070; }
        .header-area .alazea-main-menu .search-form .closeIcon:hover {
          color: #70c745; }
        @media only screen and (max-width: 767px) {
          .header-area .alazea-main-menu .search-form .closeIcon {
            top: 31px;
            right: 35px; } }
      .header-area .alazea-main-menu .search-form.active {
        opacity: 1;
        visibility: visible; }
  .header-area .is-sticky .alazea-main-menu {
    background-color: #111111;
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 9999;
    box-shadow: 0 5px 50px 15px rgba(0, 0, 0, 0.2); }
  .header-area #searchIcon {
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms;
    color: #ffffff;
    font-size: 20px;
    cursor: pointer;
    margin-left: 50px; }
    @media only screen and (min-width: 768px) and (max-width: 991px) {
      .header-area #searchIcon {
        margin-left: 30px;
        margin-top: 15px; } }
    @media only screen and (max-width: 767px) {
      .header-area #searchIcon {
        margin-left: 30px;
        margin-top: 15px; } }
    .header-area #searchIcon:hover, .header-area #searchIcon:focus {
      color: #70c745; }

/* :: 5.0 Hero Slides Area */
.hero-area,
.hero-post-slides {
  position: relative;
  z-index: 1; }

.single-hero-post {
  width: 100%;
  height: 930px;
  position: relative;
  z-index: 3;
  overflow: hidden; }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .single-hero-post {
      height: 690px; } }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .single-hero-post {
      height: 600px; } }
  @media only screen and (max-width: 767px) {
    .single-hero-post {
      height: 650px; } }
  @media only screen and (min-width: 480px) and (max-width: 767px) {
    .single-hero-post {
      height: 500px; } }
  .single-hero-post .slide-img {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: -10;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0; }
  .single-hero-post .hero-slides-content {
    position: relative;
    z-index: 1;
    margin-top: 132px; }
    @media only screen and (max-width: 767px) {
      .single-hero-post .hero-slides-content {
        margin-top: 112px; } }
    .single-hero-post .hero-slides-content h2 {
      font-size: 45px;
      letter-spacing: 1px;
      color: #ffffff;
      text-transform: uppercase; }
      @media only screen and (min-width: 992px) and (max-width: 1199px) {
        .single-hero-post .hero-slides-content h2 {
          font-size: 36px; } }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-hero-post .hero-slides-content h2 {
          font-size: 30px; } }
      @media only screen and (max-width: 767px) {
        .single-hero-post .hero-slides-content h2 {
          font-size: 24px; } }
    .single-hero-post .hero-slides-content p {
      font-size: 18px;
      color: #ffffff;
      margin-bottom: 50px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single-hero-post .hero-slides-content p {
          font-size: 18px; } }
      @media only screen and (max-width: 767px) {
        .single-hero-post .hero-slides-content p {
          font-size: 16px; } }
    @media only screen and (max-width: 767px) {
      .single-hero-post .hero-slides-content a {
        min-width: 125px;
        padding: 0 10px; } }

.hero-post-slides .owl-item.center .single-hero-post .slide-img {
  -webkit-animation: slide 24s linear infinite;
  animation: slide 24s linear infinite; }

@-webkit-keyframes slide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
@keyframes slide {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1); }
  50% {
    -webkit-transform: scale(1.3);
    transform: scale(1.3); }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1); } }
/* :: 6.0 Subscribe Newsletter Area */
.subscribe-newsletter-area {
  position: relative;
  z-index: 1;
  padding: 60px 0;
  background-size: cover;
  background-position: top right; }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .subscribe-newsletter-area .subscribe-form {
      margin-top: 50px; } }
  @media only screen and (max-width: 767px) {
    .subscribe-newsletter-area .subscribe-form {
      margin-top: 50px; } }
  .subscribe-newsletter-area .subscribe-form form {
    position: relative;
    z-index: 1; }
    .subscribe-newsletter-area .subscribe-form form input {
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms;
      width: 100%;
      height: 46px;
      background-color: #ffffff;
      padding: 10px 20px;
      border: none; }
      .subscribe-newsletter-area .subscribe-form form input:focus {
        box-shadow: 0 1px 15px rgba(0, 0, 0, 0.15); }
    .subscribe-newsletter-area .subscribe-form form button {
      position: absolute;
      top: 0;
      right: 0;
      z-index: 10;
      border: none;
      border-radius: 0 2px 2px 0; }
  .subscribe-newsletter-area .subscribe-side-thumb .first-img {
    position: absolute;
    top: -30px;
    left: 5%;
    z-index: 10; }

/* :: 7.0 New Arrivals Products Area */
.single-product-area {
  position: relative;
  z-index: 1;
  -webkit-transition-duration: 500ms;
  transition-duration: 500ms; }
  .single-product-area .product-img {
    position: relative;
    z-index: 1;
    -webkit-transition-duration: 500ms;
    transition-duration: 500ms; }
    .single-product-area .product-img a {
      display: block; }
    .single-product-area .product-img img {
      position: relative;
      z-index: 1;
      width: 100%; }
    .single-product-area .product-img .product-tag a {
      background-color: #429edf;
      border-radius: 2px;
      display: inline-block;
      height: 20px;
      padding: 0 10px;
      line-height: 20px;
      text-transform: uppercase;
      color: #ffffff;
      font-weight: 700;
      font-size: 12px;
      position: absolute;
      top: 20px;
      left: 20px;
      z-index: 10; }
    .single-product-area .product-img .product-tag.sale-tag a {
      background-color: #e61d47; }
    .single-product-area .product-img .product-meta {
      position: absolute;
      bottom: 30px;
      left: 15px;
      right: 15px;
      z-index: 100;
      visibility: hidden;
      opacity: 0;
      -webkit-transition-duration: 500ms;
      transition-duration: 500ms; }
      .single-product-area .product-img .product-meta a {
        font-size: 16px;
        color: #ffffff;
        font-weight: 600;
        background-color: #303030;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 50px;
        flex: 0 0 50px;
        max-width: 50px;
        width: 50px;
        height: 50px;
        line-height: 50px;
        text-transform: uppercase;
        text-align: center; }
        .single-product-area .product-img .product-meta a:hover, .single-product-area .product-img .product-meta a:focus {
          background-color: #70c745; }
        @media only screen and (min-width: 992px) and (max-width: 1199px) {
          .single-product-area .product-img .product-meta a {
            font-size: 11px; } }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .single-product-area .product-img .product-meta a {
            font-size: 11px; } }
        @media only screen and (max-width: 767px) {
          .single-product-area .product-img .product-meta a {
            font-size: 14px; } }
      .single-product-area .product-img .product-meta .add-to-cart-btn {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 calc(100% - 100px);
        flex: 0 0 calc(100% - 100px);
        max-width: calc(100% - 100px);
        width: calc(100% - 100px);
        border-left: 1px solid rgba(235, 235, 235, 0.5);
        border-right: 1px solid rgba(235, 235, 235, 0.5); }
  .single-product-area .product-info a p {
    margin-bottom: 0;
    -webkit-transition-duration: 300ms;
    transition-duration: 300ms; }
    .single-product-area .product-info a p:hover, .single-product-area .product-info a p:focus {
      color: #70c745; }
  .single-product-area .product-info h6 {
    margin-bottom: 0;
    font-size: 18px; }
  .single-product-area:hover .product-img {
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.15); }
    .single-product-area:hover .product-img .product-meta {
      visibility: visible;
      opacity: 1; }

/* :: 8.0 Shop Page Area CSS */
.shop-sorting-data {
  position: relative;
  z-index: 1;
  padding-bottom: 25px;
  margin-bottom: 50px;
  border-bottom: 1px solid #ebebeb; }
  @media only screen and (max-width: 767px) {
    .shop-sorting-data .shop-page-count {
      margin-bottom: 30px; } }
  .shop-sorting-data .shop-page-count p {
    font-size: 18px;
    color: #707070;
    margin-bottom: 0;
    font-weight: 500; }
  .shop-sorting-data .search_by_terms {
    position: relative;
    z-index: 1; }
    .shop-sorting-data .search_by_terms select {
      width: 180px;
      height: 40px;
      color: #51545f;
      font-size: 16px;
      background-color: #f5f5f5;
      border: 1px solid #ebebeb;
      border-radius: 2px;
      margin-left: 30px; }
      @media only screen and (max-width: 767px) {
        .shop-sorting-data .search_by_terms select {
          margin-left: 0;
          margin-bottom: 15px; } }
      @media only screen and (min-width: 480px) and (max-width: 767px) {
        .shop-sorting-data .search_by_terms select {
          margin-left: auto;
          margin-right: 15px;
          margin-bottom: 0; } }
      .shop-sorting-data .search_by_terms select:focus {
        box-shadow: none; }

.shop-widget {
  position: relative;
  z-index: 1; }
  .shop-widget .widget-title {
    text-transform: uppercase;
    margin-bottom: 20px; }
  .shop-widget .custom-control .custom-control-label {
    font-size: 16px;
    color: #707070; }
  .shop-widget .custom-checkbox .custom-control-input:checked ~ .custom-control-label::before {
    background-color: #70c745; }
  .shop-widget .single-best-seller-product {
    position: relative;
    z-index: 1;
    margin-bottom: 20px; }
    .shop-widget .single-best-seller-product::after {
      margin-bottom: 0; }
    .shop-widget .single-best-seller-product .product-thumbnail {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 70px;
      flex: 0 0 70px;
      max-width: 70px;
      width: 70px;
      margin-right: 30px; }
    .shop-widget .single-best-seller-product .product-info a {
      display: block;
      color: #707070;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 5px; }
      .shop-widget .single-best-seller-product .product-info a:hover, .shop-widget .single-best-seller-product .product-info a:focus {
        color: #303030; }
    .shop-widget .single-best-seller-product .product-info p {
      margin-bottom: 0;
      color: #303030;
      font-weight: 500; }
    .shop-widget .single-best-seller-product .product-info .ratings i {
      font-size: 12px;
      color: #ff9800; }

.slider-range-price {
  position: relative;
  z-index: 1; }

.shop-widget .ui-slider-handle {
  background-color: #70c745;
  border: none;
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  z-index: 30;
  top: -3px; }

.ui-slider-handle.first-handle {
  display: none !important; }

.shop-widget .ui-slider-range.ui-widget-header.ui-corner-all {
  background-color: #70c745;
  position: absolute;
  height: 6px;
  width: auto;
  z-index: 10;
  left: 2px !important; }

.shop-widget .ui-slider-horizontal {
  height: 6px;
  background-color: #f5f5f5;
  border-radius: 10px; }

.shop-widget .range-price {
  font-size: 16px;
  font-weight: 500;
  margin-top: 15px;
  text-transform: uppercase; }

.single_product_thumb {
  position: relative;
  z-index: 1; }
  @media only screen and (max-width: 767px) {
    .single_product_thumb {
      margin-bottom: 50px; } }
  .single_product_thumb .carousel-indicators {
    position: relative;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 15;
    margin-right: 0;
    margin-left: 0;
    margin-top: 30px; }
    .single_product_thumb .carousel-indicators li {
      -webkit-box-flex: 0;
      -ms-flex: 0 0 100px;
      flex: 0 0 100px;
      width: 100px;
      height: 100px;
      margin-right: 15px;
      margin-left: 15px;
      cursor: pointer;
      border: 1px solid transparent;
      background-size: cover; }
      @media only screen and (max-width: 767px) {
        .single_product_thumb .carousel-indicators li {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 60px;
          flex: 0 0 60px;
          width: 60px;
          height: 60px; } }
      .single_product_thumb .carousel-indicators li.active {
        border: 1px solid #70c745; }

.single_product_desc {
  position: relative;
  z-index: 1; }
  .single_product_desc .title {
    font-size: 30px;
    margin-bottom: 10px;
    text-transform: uppercase; }
    @media only screen and (max-width: 767px) {
      .single_product_desc .title {
        font-size: 24px; } }
  .single_product_desc .price {
    font-size: 26px;
    font-weight: 600;
    color: #70c745;
    margin-bottom: 30px; }
  .single_product_desc .short_overview {
    margin-bottom: 30px; }
    .single_product_desc .short_overview p {
      font-size: 14px; }
  .single_product_desc .cart--area {
    position: relative;
    z-index: 1;
    border-bottom: 1px solid #ebebeb;
    padding-bottom: 50px; }
  .single_product_desc .cart {
    position: relative;
    z-index: 1; }
    .single_product_desc .cart .quantity {
      position: relative;
      z-index: 1;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 130px;
      flex: 0 0 130px;
      max-width: 130px;
      width: 130px; }
      @media only screen and (min-width: 768px) and (max-width: 991px) {
        .single_product_desc .cart .quantity {
          -webkit-box-flex: 0;
          -ms-flex: 0 0 70px;
          flex: 0 0 70px;
          max-width: 70px;
          width: 70px; } }
      .single_product_desc .cart .quantity .qty-text {
        height: 46px;
        padding: 5px 15px;
        width: 130px;
        -moz-appearance: textfield;
        -webkit-appearance: textfield;
        appearance: textfield;
        font-size: 14px;
        border: none;
        background-color: #f2f4f5;
        text-align: center; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .single_product_desc .cart .quantity .qty-text {
            width: 70px; } }
      .single_product_desc .cart .quantity .qty-minus,
      .single_product_desc .cart .quantity .qty-plus {
        display: block;
        height: 100%;
        position: absolute;
        left: 10px;
        text-align: center;
        top: 0;
        width: 30px;
        z-index: 99;
        cursor: pointer;
        font-size: 8px;
        line-height: 46px;
        color: #303030; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .single_product_desc .cart .quantity .qty-minus,
          .single_product_desc .cart .quantity .qty-plus {
            left: 0; } }
      .single_product_desc .cart .quantity .qty-plus {
        left: auto;
        right: 10px; }
        @media only screen and (min-width: 768px) and (max-width: 991px) {
          .single_product_desc .cart .quantity .qty-plus {
            right: 0; } }
  .single_product_desc .wishlist-compare {
    position: relative;
    z-index: 1; }
    @media only screen and (max-width: 767px) {
      .single_product_desc .wishlist-compare {
        margin-top: 30px; } }
    @media only screen and (min-width: 480px) and (max-width: 767px) {
      .single_product_desc .wishlist-compare {
        margin-top: 0; } }
    .single_product_desc .wishlist-compare a {
      background-color: #f2f4f5;
      display: inline-block;
      width: 46px;
      height: 46px;
      text-align: center;
      line-height: 46px;
      font-size: 18px; }
  .single_product_desc .products--meta {
    position: relative;
    z-index: 1;
    padding-top: 50px; }
    .single_product_desc .products--meta p {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex; }
      .single_product_desc .products--meta p span:first-child {
        font-weight: 500;
        color: #303030;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 150px;
        flex: 0 0 150px;
        max-width: 150px;
        width: 150px; }
      .single_product_desc .products--meta p span:last-child {
        font-weight: 400;
        color: #707070; }
        .single_product_desc .products--meta p span:last-child a {
          color: #cccccc;
          font-size: 14px;
          margin-right: 15px; }
          .single_product_desc .products--meta p span:last-child a:hover {
            color: #70c745; }

.product_details_tab {
  position: relative;
  z-index: 1;
  padding: 50px 0;
  border-top: 1px solid #ebebeb;
  border-bottom: 1px solid #ebebeb; }
  .product_details_tab .nav-tabs {
    border-bottom: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 30px; }
    .product_details_tab .nav-tabs .nav-link {
      border: none;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      padding: 0 15px;
      font-size: 20px;
      color: #303030; }
      .product_details_tab .nav-tabs .nav-link.active, .product_details_tab .nav-tabs .nav-link:hover {
        color: #70c745; }
  .product_details_tab .additional_info_area p {
    color: #303030; }
    .product_details_tab .additional_info_area p span {
      color: #707070; }

.product_details_tab .review-rating i {
  color: #ff9800;
  font-size: 14px; }

.product_details_tab .review-rating > span {
  font-size: 16px;
  font-weight: 500;
  color: #303030; }

.submit_a_review_area form .stars {
  background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==") repeat-x 0 0;
  width: 150px; }
  .submit_a_review_area form .stars::after, .submit_a_review_area form .stars::before {
    display: table;
    content: ""; }
  .submit_a_review_area form .stars::after {
    clear: both; }
  .submit_a_review_area form .stars input[type="radio"] {
    position: absolute;
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0); }
    .submit_a_review_area form .stars input[type="radio"].star-5:checked ~ span {
      width: 100%; }
    .submit_a_review_area form .stars input[type="radio"].star-4:checked ~ span {
      width: 80%; }
    .submit_a_review_area form .stars input[type="radio"].star-3:checked ~ span {
      width: 60%; }
    .submit_a_review_area form .stars input[type="radio"].star-2:checked ~ span {
      width: 40%; }
    .submit_a_review_area form .stars input[type="radio"].star-1:checked ~ span {
      width: 20%; }
  .submit_a_review_area form .stars label {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0 !important;
    padding: 0 !important;
    text-indent: -999em;
    float: left;
    position: relative;
    z-index: 10;
    background: transparent !important;
    cursor: pointer; }
    .submit_a_review_area form .stars label:hover ~ span {
      background-position: 0 -30px; }
    .submit_a_review_area form .stars label.star-5:hover ~ span {
      width: 100% !important; }
    .submit_a_review_area form .stars label.star-4:hover ~ span {
      width: 80% !important; }
    .submit_a_review_area form .stars label.star-3:hover ~ span {
      width: 60% !important; }
    .submit_a_review_area form .stars label.star-2:hover ~ span {
      width: 40% !important; }
    .submit_a_review_area form .stars label.star-1:hover ~ span {
      width: 20% !important; }
  .submit_a_review_area form .stars span {
    display: block;
    width: 0;
    position: relative;
    top: 0;
    left: 0;
    height: 30px;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAABaCAYAAACv+ebYAAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAALEgAACxIB0t1+/AAAABx0RVh0U29mdHdhcmUAQWRvYmUgRmlyZXdvcmtzIENTNXG14zYAAAAWdEVYdENyZWF0aW9uIFRpbWUAMDcvMDMvMTNJ3Rb7AAACnklEQVRoge2XwW3bMBSGPxa9NxtIGzTAW8DdRL7o3A0qb+BrdNIm9QAm0G7gbJBMwB5MoVJNUSRFIXGqHwhkmXr68hOPNH9ljOEt9OlNqBs4RlrrSmtdpdZ/Ti0EGnvtUoqTHFunBVCkuk6d6mbi83rggdteSa5THDeB3+UDO9z2inatXFum1roESuAReAB29vp15n2/gRfgZK+/gIuIXLxgrfUO+Bnzn0fom4ic+pvRVNuB/QrQ/RB6A7bwLjN8b985krO5MsKd0ElwJvgk1AteCPdCYWI5/SutddQxRUTU3DOzG4hd01EKqQnZuaLBITUh4F0CeLYm5CDw6PjuFTjaz9+BLwE1I8VO9StwAEoRaUSkseMHO+aqcWq2qwcdfQCOIvIy8dwDV/c/YL6zvWDbnQ3QuH5hltQEreM1dH/n6g28gT8eWLVUqqVKrb+vtGidFkCR6vp+0uLAba8k1/eRFh1ue0W7dv4sqpaSjGnR1Fy8YNWyY8W0aGpO/c1oqu3AKmlxCL0BW3iXGb637xzJ2VwZ4U7oJDgTfBLqBS+Ee6EQeMpULVFHUVOzPC3aNR2lkJotLbr0vtKiqWlMTcNaaXHQ0QfgaGqcaVG1jNLibGcbYyb/eDIlT6bjyZS+51JqtrS4gTfw/wzWqkKrKrU8fQPR6gKAmDKlPM3x1WkBFKmu0xxf3fZR5jnFdbzjv257JbmOdzx22yvadZzjW7e9ol27HWtVkjEtIubiB2u1Y8W0iJhTfzOe6uvAKmlxCL0FX+FdZvjevnMkd3Plgzuh0+A88EmoH7wM7oVC6AaiVdwuI2Z5WrRrOk4BNVtadOl9pUXENIhpWCstDjr6ABwR40yLaDVKi7Od7U1/Z0pzpjNngtNiaM2WFj8++A+motm0NTqjmwAAAABJRU5ErkJggg==") repeat-x 0 -60px;
    -webkit-transition: -webkit-width 0.5s;
    -webkit-transition: width 0.5s;
    transition: width 0.5s; }

.review-details p {
  font-size: 12px; }

.submit_a_review_area h4 {
  font-size: 20px; }
.submit_a_review_area .form-group > label {
  font-size: 14px; }
.submit_a_review_area input,
.submit_a_review_area select {
  font-size: 14px;
  width: 100%;
  height: 40px;
  border: none;
  background-color: #f2f4f5;
  border-radius: 0; }
.submit_a_review_area textarea {
  width: 100%;
  height: 100px;
  border: none;
  border-radius: 0;
  background-color: #f2f4f5; }

/* :: 9.0 Cart Area CSS */
.cart-item {
  display: flex;
  padding: 20px;
  gap: 20px;
  border-bottom: 1px solid #ddd;
  align-items: center;
}

.cart-item-image {
  flex-shrink: 0;
  width: 120px;
  height: 120px;
}

.cart-item-image .image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.cart-item-details {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.cart-item-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.cart-item-title {
  font-size: 20px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.8);
}

.cart-item-price {
  font-size: 16px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.cart-item-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}

.cart-item-options {
  display: flex;
  align-items: center;
  gap: 20px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 16px;
}

.cart-item-option {
  display: flex;
  align-items: center;
  gap: 10px;
}

.cart-item-option-title {
  font-weight: bold;
}

.cart-item-delete {
  cursor: pointer;
  color: rgba(0, 0, 0, 0.5);
  &:hover {
    color: black;
  }
  font-size: 16px;
}
.summary {
  background-color: #fff;
  border-radius: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.summary-title {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
}

.summary-content {
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
}

.summary-subtotal {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.subtotal-title, .subtotal-value {
  font-size: 20px;
  font-weight: bold;
  color: #000;
}

.summary-note {
  font-size: 16px;
  color: #666;
}
.thankyou-message {
  text-align: center;
  position: relative;
  margin: 30px 0;
}
.Toastify__toast {
  min-height: 50px;
  font-size: 14px;
}
.thankyou-message::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 10%;
  right: 10%;
  border-top: 1px solid #000;
  z-index: 1;
}

.thankyou-message span {
  background: #fff;
  padding: 0 15px;
  position: relative;
  z-index: 2;
  font-size: 24px;
  font-family: 'Miteria';
}
.checkout-button-container {
  display: flex;
  justify-content: end;
  margin-bottom: 20px;
}

.checkout-button {
  width: 300px;
  padding: 20px 0;
  border-radius: 50px;
  background-color: black;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  transition: transform 0.3s ease;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.checkout-button:active {
  transform: scale(0.95);
}

.checkout-button:hover {
  opacity: 0.75;
}

